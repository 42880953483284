<template>
  <div class="auth-wrapper auth-v2 min-vh">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper side-panel fade-in">
            <img
              height="100%"
              width="100%"
              class="side-panel-image"
              :src="require('../Login/assets/landignpage.jpg')"
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat v-if="!sent">
                <v-card-text
                  class="d-flex justify-center align-center text-center mb-8"
                  v-if="$vuetify.breakpoint.mdAndDown"
                >
                  <v-img
                    :src="
                      require(`@/assets/Logo_${
                        $vuetify.theme.dark ? 'blanco' : 'negro'
                      }.svg`)
                    "
                    max-width="100%"
                    class=""
                  ></v-img>
                </v-card-text>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t("Reset your password") }}
                  </p>
                  <p class="mb-2">
                    {{ $t("Enter your new password") }}
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <ValidationObserver v-slot="{ invalid }">
                    <v-form @submit.prevent="handleSend()" spellcheck="false">
                      <ValidationProvider
                        :name="$t('Password')"
                        rules="required|min:6|confirmed:password"
                        v-slot="{ errors }"
                        :debounce="450"
                      >
                        <v-text-field
                          v-model="userData.password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          :label="$t('Password')"
                          :placeholder="$t('Password')"
                          :append-icon="
                            isPasswordVisible
                              ? icons.mdiEyeOffOutline
                              : icons.mdiEyeOutline
                          "
                          hide-details="auto"
                          class="mb-2"
                          @click:append="isPasswordVisible = !isPasswordVisible"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                      <ValidationProvider
                        :name="$t('PasswordConfirm')"
                        rules="required|min:6"
                        v-slot="{ errors }"
                        :debounce="450"
                        vid="password"
                      >
                        <v-text-field
                          v-model="userData.passwordConfirm"
                          outlined
                          :type="isPasswordVisibleConfirm ? 'text' : 'password'"
                          :label="$t('PasswordConfirm')"
                          :placeholder="$t('PasswordConfirm')"
                          :append-icon="
                            isPasswordVisibleConfirm
                              ? icons.mdiEyeOffOutline
                              : icons.mdiEyeOutline
                          "
                          hide-details="auto"
                          class="mb-2"
                          @click:append="
                            isPasswordVisibleConfirm = !isPasswordVisibleConfirm
                          "
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>

                      <div
                        class="
                          d-flex
                          align-center
                          justify-space-between
                          flex-wrap
                        "
                        v-if="true"
                      >
                        <span>
                          <v-fade-transition mode="out-in" v-if="!false">
                            <v-avatar
                              color="grey-lighten-2"
                              size="36"
                              @click="handleLocale()"
                              class="mySwitcher"
                            >
                              {{
                                locales.find((l) => l.locale === $i18n.locale)
                                  .title
                              }}
                            </v-avatar>
                          </v-fade-transition>

                          <app-bar-theme-switcher
                            class="mx-4"
                          ></app-bar-theme-switcher>
                        </span>
                      </div>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                        :disabled="invalid"
                        @click="handleSend()"
                      >
                        {{ $t("Send") }}
                      </v-btn>
                    </v-form>
                  </ValidationObserver>
                </v-card-text>
              </v-card>
              <v-card flat v-if="sent">
                <v-card-text
                  class="d-flex justify-center align-center text-center mb-8"
                  v-if="$vuetify.breakpoint.mdAndDown"
                >
                  <v-img
                    :src="
                      require(`@/assets/Logo_${
                        $vuetify.theme.dark ? 'blanco' : 'negro'
                      }.svg`)
                    "
                    max-width="100%"
                    class=""
                  ></v-img>
                </v-card-text>
                <v-card-text>
                  <p
                    class="
                      text-2xl
                      font-weight-semibold
                      text--primary
                      mb-2
                      d-flex
                      align-center
                    "
                  >
                    {{ $t("Password reset") }}
                    <v-icon class="ml-2">
                      {{ icons.mdiCheckBold }}
                    </v-icon>
                  </p>
                  <p class="mb-2">
                    {{ $t("You can log back in") }}
                  </p>
                </v-card-text>
                <v-card-text>
                  <div
                    class="d-flex align-center justify-space-between flex-wrap"
                    v-if="true"
                  >
                    <span>
                      <v-fade-transition mode="out-in" v-if="!false">
                        <v-avatar
                          color="grey-lighten-2"
                          size="36"
                          @click="handleLocale()"
                          class="mySwitcher"
                        >
                          {{
                            locales.find((l) => l.locale === $i18n.locale).title
                          }}
                        </v-avatar>
                      </v-fade-transition>

                      <app-bar-theme-switcher
                        class="mx-4"
                      ></app-bar-theme-switcher>
                    </span>
                    <router-link class="ms-3" :to="{ name: 'auth-login' }">
                      <v-icon x-small>
                        {{ icons.mdiArrowLeft }}
                      </v-icon>
                      <span class="ml-2">{{ $t("GoBack") }}</span></router-link
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {
  mdiArrowLeft,
  mdiCheckBold,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import AppBarThemeSwitcher from "@core/layouts/components/app-bar/AppBarThemeSwitcher.vue";
import { setVeeLocale } from "@/utils";
import { loadLanguageAsync } from "@/plugins/i18n";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    AppBarThemeSwitcher,
  },
  data() {
    return {
      isPasswordVisible: false,
      isPasswordVisibleConfirm: false,

      icons: {
        mdiArrowLeft,
        mdiCheckBold,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      userData: {
        password: "",
        passwordConfirm: "",
      },
      locales: [
        {
          title: "ES",
          locale: "es",
        },
        {
          title: "EN",
          locale: "en",
        },
      ],
      sent: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleLocale() {
      let prevLocal = this.$i18n.locale;
      let locale = "";
      switch (prevLocal) {
        case "es":
          locale = "en";
          break;
        case "en":
          locale = "es";
          break;
        default:
          break;
      }
      setVeeLocale(locale);
      loadLanguageAsync(locale);
    },
    async handleSend() {
      this.setLoading(true);
      await new Promise((r) => setTimeout(r, 500));
      this.sent = true;
      this.setLoading(false);
    },
  },
};
</script>
<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
.side-panel {
  // background-image: url("../assets/login_side_panel.png");
  background: white;
  max-height: 100vh;
  &-image {
    object-fit: cover;
    // object-fit: contain;
  }
}
.mySwitcher:hover {
  cursor: pointer;
}
</style>
